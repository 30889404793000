
/*=============================================
=            fun fact            =
=============================================*/



.fun-fact-wrapper{
    margin-bottom: -30px;
}

.funfact-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
    
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: $black;
        opacity: 0.75;
        z-index: -1;
    }
}
/*-- Funfact --*/
.single-fact {
    text-align: center;
    & img {
        margin-bottom: 20px;
    }
    & .counter {
        font-size: 50px;
        font-weight: 700;
        color: $white;
        line-height: 1;
        margin-bottom: 15px;
    }
    & h4 {
        line-height: 1;
        margin: 0;
        color: $white;
    }
}

/*=====  End of fun fact  ======*/

